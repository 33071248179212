<template>
    <div >
        <h3>Price Management</h3>
        <div class="dF aC mt-4">
            <h6 style="cursor:pointer; padding:0 20px 10px 20px" @click="keyTab = 1; $store.commit('CHANGE_TAB', 'lot')" :style="keyTab == 1 ? 'border-bottom:2px solid #FD9026; color:#000':'border-bottom:none; color:#9EA0A5'" class="mr-5">Lot Premium Price</h6>
            <h6 style="cursor:pointer; padding:0 20px 10px 20px" @click="keyTab = 2; $store.commit('CHANGE_TAB', 'elevation')" :style="keyTab == 2 ? 'border-bottom:2px solid #FD9026; color:#000':'border-bottom:none; color:#9EA0A5'">Elevation Price</h6>
        </div>
        <div v-if="keyTab == 1">

            <div class="dF aC mt-5" style="justify-content:space-between">
                <div class="dF aC">
                    <a-checkbox :indeterminate="indeterminateLots" :checked="checkAllLots" @change="onchangeAllLots" class="mr-3" />
                    <h5 style="margin-bottom:0">Lots</h5>
                    <div style="color:#9EA0A5" class="ml-3">{{`${premiumsLotLength} of ${orderedLots.length} have premiums`}}</div>
                </div>
                <div class="dF aC">
                    <div @click="$p >= 40 ? $store.commit('OPEN_BUMP', {type:'lots',ids:selectedLots, cb:onBump}) : $message.error('You do not have permission to adjust the price')" v-if="selectedLots.length != 0" class="dF aC px-3 py-1 mr-3" style="border-radius:3px; background-color:#F79425; cursor:pointer; color:#FFF; height:33.72px">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                        <div class="ml-3">Adjust Price</div>
                    </div>
                    <div @click="openFilter" class="dF aC px-3 py-1" style="border-radius:3px; background-color:#FFF; cursor:pointer; border:1px solid #ECEBED">
                        <div>Filters</div>
                        <div class="ml-3" style="height:24px"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg></div>
                    </div>
                </div>
            </div>
            <div v-if="orderedLots.length" class="dF mt-5" style="gap: 2rem; flex-direction: column;">
                <template v-for="lot in orderedLots">
                    <div v-if="getPremiums(lot.id).length && lot.status == 'available'" :key="lot.id" :data-lot-id="lot.id">
                        <div class="display-card py-3 px-3">
                            <div class="dF aC" style="justify-content:space-between">
								<div id="left" v-if="showScrollArrows" @click="scrollLeft(lot.id)"><a-icon type="left" /></div>
								<div :id="lot.id" class="price-content price-div">
	                               	<div class="dF">
	                                    <a-checkbox class="pr-3" @change="onChangeLots(lot.id)" :checked="selectedLots.includes(lot.id)" />
	                                    <div class="pr-3" style="min-width:60px; width:90px">
	                                        <div style="color:#9EA0A5">Lot #</div>
	                                        <div>{{lot.name}}</div>
	                                    </div>
	                                </div>
	                                <div class="pr-3" style="min-width:80px; width:150px">
	                                    <div style="color:#9EA0A5">Frontage</div>
	                                    <div>{{getFrontage(lot.id)}}'</div>
	                                </div>
	                                <div class="pr-3" style="min-width:100px; width:180px">
	                                    <div style="color:#9EA0A5">Model Type</div>
	                                    <div>{{getModelType(lot.id)}}</div>
	                                </div>
									<div class="pr-3" style="min-width:100px; width:180px">
	                                    <div style="color:#9EA0A5">Sq. Ft.</div>
	                                    <div v-for="(sqft,sqftI) in getSqft(lot.id)" :key="sqftI">
											{{sqft}}
										</div>
	                                </div>
	                                <div class="pr-3" style="min-width:200px; width:250px">
	                                    <div style="color:#9EA0A5">Conditions/Premiums</div>
	                                    <div class="dF aC" v-if="getPremiums(lot.id).length">
	                                        <a-tooltip overlayClassName="change-tooltip-color" :title="getPremiums(lot.id,true).join(',  ')" class="dF aC">
	                                            <div>{{getPremiums(lot.id)[0]}}</div>
	                                            <div v-if="getPremiums(lot.id).length > 1" class="px-2 ml-3" style="background-color:#F7F5F9; border-radius:6px; border:1px solid #D0C9D6">{{`${getPremiums(lot.id).length}+`}}</div>
	                                        </a-tooltip>
	                                    </div>
	                                    <span v-else>N/A</span>
	                                </div>
	                                <div class="pr-3" style="min-width:70px; width:140px">
	                                    <div style="color:#9EA0A5">Block #</div>
	                                    <div>{{lot.block && lot.block.length? lot.block : 'N/A'}}</div>
	                                </div>
	                                <div class="pr-3" style="min-width:170px; width:200px">
	                                    <div style="color:#9EA0A5">Gradings</div>
	                                    <div class="dF aC" v-if="getGradings(lot.id).length">
	                                        <a-tooltip overlayClassName="change-tooltip-color" :title="getGradings(lot.id,true).join(',  ')" class="dF aC">
	                                            <div>{{getGradings(lot.id)[0]}}</div>
	                                            <div v-if="getGradings(lot.id).length > 1" class="px-2 ml-3" style="background-color:#F7F5F9; border-radius:6px; border:1px solid #D0C9D6">{{`${getGradings(lot.id).length}+`}}</div>
	                                        </a-tooltip>
	                                    </div>
	                                    <span v-else>N/A</span>
	                                </div>
	                                <div class="pr-3" style="min-width: 170px; width:190px">
	                                    <div style="color:#9EA0A5">Available Home(s)</div>
	                                    <div v-for="unit in lot.units" :key="unit">{{getUnitName(unit)}}</div>
	                                </div>
	                                <div class="pr-3" style="min-width:120px; width:150px">
	                                    <div style="color:#9EA0A5">Lot Premium</div>
	                                    <div>{{lot.other && lot.other.premiums && Object.keys(lot.other.premiums).length ? `$${getLotPremiumPrice(lot.id).toLocaleString('US')}` : 'N/A'}}</div>
	                                </div>
								</div>
								<div id="right" v-if="showScrollArrows" @click="scrollRight(lot.id)"><a-icon type="right" /></div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div v-else class="mt-5">
                <EmptyApp :text="'No lots found for your filter'" :img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
            </div>

        </div>
        <div v-if="keyTab == 2">
            <div class="dF aC mt-5" style="justify-content:space-between">
                <div class="dF aC">
                    <a-checkbox class="mr-3" :indeterminate="indeterminateElevations" :checked="checkAllElevations" @change="onchangeAllElevations" />
                    <h5 style="margin-bottom:0">Elevations</h5>
                    <div style="color:#9EA0A5" class="ml-3">{{`${Object.values(elevations).length} in total`}}</div>
                </div>
                <div class="dF aC">
                    <div @click="$p >= 40 ? $store.commit('OPEN_BUMP', {type:'units',ids:selectedElevations,cb:onBump}) : $message.error('You do not have permission to adjust the price')" v-if="selectedElevations.length != 0" class="dF aC px-3 py-1 mr-3" style="border-radius:3px; background-color:#F79425; cursor:pointer; color:#FFF; height:33.72px">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                        <div class="ml-3">Adjust Price</div>
                    </div>
                    <!-- <a-button class="mr-4"  v-if="Object.values(elevations).length < Object.values(storeUnits).length" @click="clearFilter('elevation')" ghost type="primary">Clear Filter <a-icon type="delete" /></a-button> -->
                    <div @click="openFilter" class="dF aC px-3 py-1" style="border-radius:3px; background-color:#FFF; cursor:pointer; border:1px solid #ECEBED">
                        <div>Filters</div>
                        <div class="ml-3" style="height:24px"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg></div>
                    </div>
                </div>
            </div>
            <div v-if="Object.values(elevations).length" class="dF mt-5" style="gap: 2rem; flex-direction: column;">
                <ElevationPriceItem :selected="selectedElevations.includes(ele.id)" @select="onchangeElevations(ele.id)" v-for="ele in Object.values(elevations)" :id="ele.id" :key="ele.id + $store.state.inventory.newTime"/>

            </div>
            <div v-else class="mt-5">
                <EmptyApp :text="'No elevations found for your filter'" :img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
            </div>
        </div>

    </div>
</template>

<script>
    import ElevationPriceItem from '@/components/inventory/ElevationPriceItem'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    export default {
        components: {ElevationPriceItem,EmptyApp},


        data() {
            return {
                keyTab:1,
                selectedLots:[],
                indeterminateElevationsx:false,
                selectedElevations:[],
				showScrollArrows: true,
				resizeObserver: null
            }
        },
        watch:{
            lotFilter(){
                this.selectedLots = []
            },
            elevationFilter(){
                this.selectedElevations = []
            }
        },
        computed:{
            premiumsLotLength(){
                let lots = this.lots
                lots = lots.filter(x => this.getPremiums(x.id).length > 0 && x.status == 'available')
                return Object.values(lots).length
            },
            elevationPrices() {
                return this.$store.getters.elevationPrices
            },
            lotFilter(){
                return this.$store.state.inventory.lotFilter
            },
            elevationFilter(){
                return this.$store.state.inventory.elevationFilter
            },
            storeUnits() {
                let units = {}
                Object.values(this.$store.state.inventory.units).forEach(unit => {
                    if (unit.buildingType == 'models') {
                        units[unit.id] = unit
                    }
                })
                return units
            },
            storeLots() {
                return this.$store.state.inventory.lots
            },
            orderedLots(){
                return this.orderAlpha(Object.values(this.lots))
            },
            lotsByPremiums() {
                return this.$store.getters.lotsByPremiums
            },
            lots() {
                let state = this.$store.state.inventory
                const orderAlpha = (array) => {
                    array = array.sort((a,b) => {
                        if (parseInt(a.name) < parseInt(b.name)) return -1
                        if (parseInt(a.name) > parseInt(b.name)) return 1
                        else return 0
                    })
                    return array
                }

                let data = state.lotFilter




                if (data.status[0] == 'any' && data.modelType[0] == 'any' && data.street == 'any' && data.tags[0] === 'any' && data.frontage[0] == 0 && data.premiums[0] == 'any' && data.price[1] == 0){
                    return orderAlpha(Object.values(state.lots))
                }

                const checkPrice = (lot,prices) => {
                    if (prices[1] == 0) return true

                    let list = []

                    Object.keys(this.lotsByPremiums.priceMap).forEach(price => {
                        if (price >= prices[0] && price <= prices[1]) {
                            list = list.concat(this.lotsByPremiums.priceMap[price])
                        }
                    })

                    if (list.includes(lot.id)) return true
                    else return false
                }

                const checkPremiums = (lot, premiums) => {
                    if (premiums[0] == 'any') return true

                    let list = []
                    if (lot.other && lot.other.premiums) {
                        Object.keys(lot.other.premiums).forEach(key => {
                            if (lot.other.premiums[key].active) list.push(key)
                        })
                    }

                    let pass = false
                    list.forEach(prem => {
                        if (premiums.includes(prem)) pass = true
                    })

                    return pass
                }

                const checkFrontage = (lot, frontages) => {
                    if (frontages[0] == 0) return true

                    let pass = true
                    lot.units.forEach(unit => {
                        let id = unit
                        if (unit.id) id = unit.id
                        if (!state.units[id] || !state.units[id].unitGroup || !frontages.includes(state.units[id].unitGroup.size) ) pass = false
                    })
                    return pass
                }

                const checkModelType = (lot,types) => {

                    if (types[0] === 'any') return true

                    let pass = true
                    lot.units.forEach(unit => {
                        let id = unit
                        if (unit.id) id = unit.id
                        if (!state.units[id] || !state.units[id].unitGroup || !types.includes(state.units[id].unitGroup.type) ) pass = false
                    })
                    return pass
                }

                const checkTags = (lot,tags) => {

                    if (tags[0] === 'any') return true

                    const isFound = (arr1,arr2) => {
                        let found = false
                        arr1.forEach(str => {
                            if (arr2.includes(str)) found = true
                        })
                        return found
                    }

                    let pass = true
                    if (!isFound(lot.tags,tags)) pass = false
                    return pass
                }

                const checkStatus = (lot,status) => {
                    if (status[0] === 'any') return true

                    return status.includes(lot.status) || status.includes(lot.publishStatus)
                }
                const checkStreet = (lot,street) => {
                    if (street == 'any') return true
                    return lot.street == street
                }

                return orderAlpha(Object.values(state.lots).filter(lot => {

                    if (!checkModelType(lot,data.modelType)) return false
                    if (!checkTags(lot,data.tags)) return false
                    if (!checkStatus(lot,data.status)) return false
                    if (!checkStreet(lot,data.street)) return false
                    if (!checkFrontage(lot,data.frontage)) return false
                    if (!checkPremiums(lot, data.premiums)) return false
                    if (!checkPrice(lot, data.price)) return false
                    // if (!checkElevation(lot,data.elevation)) return false
                    return true

                }))


            },
			units() {
				let state = this.$store.state.inventory
				let units = Object.values(state.units);

				let unitsObj = {};
				units.forEach((unit) => {
					if (unit.packages && unit.packages[0] && unit.packages[0].price) {
						unitsObj[unit.id] = unit
					}
				})
				return unitsObj
			},
            elevations() {
                let state = this.$store.state.inventory
				let units = Object.values(state.units);
				units = units.filter(x => x.packages && x.packages.length && x.packages[0].hasOwnProperty('price'));

                const orderAlpha = (array) => {
                    array = array.sort((a,b) => {
                        if (parseInt(a.name) < parseInt(b.name)) return -1
                        if (parseInt(a.name) > parseInt(b.name)) return 1
                        else return 0
                    })
                    return array
                }

                let data = state.elevationFilter || {};


                if (data.unitGroup == 'any' && data.baths[0] == 'any' && data.beds[0] == 'any' && data.modelType[0] == 'any' && data.frontage[0] == 0 && data.price[1] == 0 && data.tags[0] == 'any' && data.elevation == 'any'){
                    return orderAlpha(units)
                }

                const checkPrice = (unit,prices) => {
                    if (prices[1] == 0) return true

                    let list = []

                    Object.keys(this.elevationPrices.priceMap).forEach(price => {
                        if (price >= prices[0] && price <= prices[1]) {
                            list = list.concat(this.elevationPrices.priceMap[price])
                        }
                    })
                    list = Array.from(new Set(list));

                    if (list.includes(unit.id)) return true
                    else return false
                }

                const checkTags = (unit,tags) => {

                    if (tags[0] === 'any') return true

                    const isFound = (arr1,arr2) => {
                        let found = false
                        arr1.forEach(str => {
                            if (arr2.includes(str)) found = true
                        })
                        return found
                    }

                    let pass = true
                    if (!isFound(unit.tags.map(x => x.id),tags)) pass = false
                    return pass
                }

                const checkElevationName = (unit, elevation) => {
                    if (elevation == 'any') return true
                    return elevation.includes(unit.name)
                }

                const checkFrontage = (unit, frontages) => {
                    if (frontages[0] == 0) return true

                    let pass = true
                    if (!unit || !unit.unitGroup || !unit.unitGroup.size || !frontages.includes(unit.unitGroup.size) ) pass = false

                    return pass
                }

                const checkModelType = (unit,types) => {

                    if (types[0] === 'any') return true

                    let pass = true
                    if (!unit || !unit.unitGroup || !unit.unitGroup.type || !types.includes(unit.unitGroup.type) ) pass = false
                    return pass
                }

                const checkBeds = (unit, beds) => {
                    if (beds[0] == 'any') return true

                    let listBeds = unit.packages.map(x => x = x.beds)

                    let pass = false
                    listBeds.forEach(bed => {
                        if (beds.includes(bed)) pass = true
                    })
                    return pass
                }

                const checkBaths = (unit, baths) => {
                    if (baths[0] == 'any') return true

                    let listBaths = unit.packages.map(x => x = x.baths)

                    let pass = false
                    listBaths.forEach(bath => {
                        if (baths.includes(bath)) pass = true
                    })
                    return pass
                }

                const checkUnitGroup = (unit, uG) => {
                    if (uG == 'any') return true
                    return unit && unit.unitGroup && unit.unitGroup.id && unit.unitGroup.id == uG
                }

                return orderAlpha(units.filter(unit => {
                    if (!checkModelType(unit,data.modelType)) return false
                    if (!checkFrontage(unit,data.frontage)) return false
                    if (!checkPrice(unit, data.price)) return false
                    if (!checkBeds(unit, data.beds)) return false
                    if (!checkBaths(unit, data.baths)) return false
                    if (!checkUnitGroup(unit, data.unitGroup)) return false
                    if (!checkElevationName(unit, data.elevation)) return false
                    // if (!checkElevation(lot,data.elevation)) return false
                    if (!checkTags(unit, data.tags)) return false
                    return true

                }))

            },
            models() {
                let models = {}
                let units = JSON.parse(JSON.stringify(this.$store.state.inventory.units))
                Object.values(units).forEach(unit => {
                    if (unit.buildingType == 'models'){
                        let model = {}
                        let price = []
                        model = JSON.parse(JSON.stringify(unit))
                        model.packages.forEach(x => {
                            if (x.price != 0 || x.price != '') {
                                price.push(x.price)
                            }
                        })
                        if (price.length != 0) {
                            let min = Math.min(...price)
                            let max = Math.max(...price)
                            if (min != max) {
                                model.displayPrice = `${min}+`
                            } else if (min == max) {
                                model.displayPrice = `${min}`
                            }
                        } else {
                            model.displayPrice = 'N/A'
                        }
                        models[model.id] = model
                    }
                })
                return models
            },
            indeterminateLots(){
                if (!this.selectedLots.length) return false
                return this.selectedLots.length < this.premiumsLotLength
            },
            indeterminateElevations(){
                if (!this.selectedElevations.length) return false
                return this.selectedElevations.length < Object.values(this.elevations).length
            },
            checkAllElevations(){
                return this.selectedElevations.length === Object.values(this.elevations).length
            },
            checkAllLots(){
                return this.selectedLots.length === this.premiumsLotLength
            },
        },
        methods:{
            onBump(){
                this.selectedLots = []
                this.selectedElevations= []
            },
            orderAlpha(array){
                if (!Array.isArray(array)) {

                    return array
                }
                    return  array.sort((a,b) => {
                        if (parseInt(a.name) < parseInt(b.name)) return -1
                        if (parseInt(a.name) > parseInt(b.name)) return 1
                        else return 0
                    })

            },
            clearFilter(type) {
                if(type == 'lots') {
                    let filter = {
                        price: [0, 0],
						frontage: [0],
						modelType: ["any"],
						tags: ["any"],
						street: "any",
						premiums: ["any"],
						status: ["any"],
                    }
                    this.$store.commit('UPDATE_LOT_FILTER', filter)
                } else if(type == 'elevation') {
                    let filter = {
                        price: [0, 0],
						frontage: [0],
						modelType: ["any"],
						beds: ["any"],
						baths: ["any"],
						unitGroup: "any",
						elevation: "any",
						tags: ["any"],
                    }
                    this.$store.commit('UPDATE_ELEVATION_FILTER', filter)
                }
            },
            onchangeAllElevations(e) {
                if (e.target.checked) {
                    Object.values(this.elevations).forEach(ele => {
                        if (!this.selectedElevations.includes(ele.id)) this.selectedElevations.push(ele.id)
                    })
                } else {
                    this.selectedElevations = []
                }
            },
            getUnitName(unit){
                let id = unit.id ? unit.id : unit
                let result = 'N/A'
                unit = this.units[id]
                if (unit) result = unit.name
                if (unit && unit.unitGroup && unit.unitGroup.name) result = `${unit.unitGroup.name} - ${unit.name}`
                return result
            },
            onchangeAllLots(e) {
                if (e.target.checked) {
                    Object.values(this.lots).forEach(ele => {
                        if (!this.selectedLots.includes(ele.id) && this.getPremiums(ele.id).length > 0 && ele.status == 'available') this.selectedLots.push(ele.id)
                    })
                } else {
                    this.selectedLots = []
                }
            },
            onchangeElevations(id) {
                if (!this.selectedElevations.includes(id)) {
                    this.selectedElevations.push(id)
                } else {
                    let index = this.selectedElevations.findIndex(x => x == id)
                    this.selectedElevations.splice(index,1)
                }

            },
            onChangeLots(id) {
                if (!this.selectedLots.includes(id)) {
                    this.selectedLots.push(id)
                } else {
                    let index = this.selectedLots.findIndex(x => x == id)
                    this.selectedLots.splice(index,1)
                }

            },
            calculatePrice(id) {
                let price = 0
                let more = []
                this.storeLots[id].units.forEach(unit => {
                    let modelPrice = ''
                    let id = ''
                    if (typeof unit == 'string') id = unit
                    else if (typeof unit == 'object') id = unit.id
                    if (this.models[id].displayPrice.includes('+')) {
                        modelPrice = this.models[id].displayPrice.substring(0,this.models[id].displayPrice.length-1)
                        modelPrice = parseFloat(modelPrice)
                        more.push(modelPrice)
                    } else {
                        modelPrice = parseFloat(this.models[id].displayPrice)
                    }
                    if (this.models[id].displayPrice == 'N/A') {
                        modelPrice = 0
                    }
                    price = price + modelPrice
                })
                if(price == 0) price = 'N/A'
                if(more.length) price = `${price}+`
                return price
            },
			getArea(id) {
				let sqft = []
				if (this.units[id] && this.units[id].packages) {
					this.units[id].packages.forEach(pack => {
						sqft.push(pack.sqft)
					})
				}
				let min = Math.min(...sqft)
				let max = Math.max(...sqft)
				if (min == 0 && max != 0) return `${max}`
				if (min == max && min == 0) return 'N/A'
				if (min != max) return `${min}-${max}`
				if (min == max) return `${max}`
			},
			getGarages(id) {
				let garages = []
				if (this.units[id] && this.units[id].packages) {
					this.units[id].packages.forEach(pack => {
						garages.push(pack.garages)
					})
				}
				let min = Math.min(...garages)
				let max = Math.max(...garages)
				if (min == 0 && max != 0) return `${max}`
				if (min == max && min == 0) return 'N/A'
				if (min != max) return `${min}-${max}`
				if (min == max) return `${max}`
			},
			getBathrooms(id) {
				let baths = []
				if (this.units[id] && this.units[id].packages) {
					this.units[id].packages.forEach(pack => {
						baths.push(pack.baths)
					})
				}
				let min = Math.min(...baths)
				let max = Math.max(...baths)
				if (min == 0 && max != 0) return `${max}`
				if (min == max && min == 0) return 'N/A'
				if (min != max) return `${min}-${max}`
				if (min == max) return `${max}`
			},
			getBedrooms(id) {
				let beds = []
				if (this.units[id] && this.units[id].packages) {
					this.units[id].packages.forEach(pack => {
						beds.push(pack.beds)
					})
				}
				let min = Math.min(...beds)
				let max = Math.max(...beds)
				if (min == 0 && max != 0) return `${max}`
				if (min == max && min == 0) return 'N/A'
				if (min != max) return `${min}-${max}`
				if (min == max) return `${max}`
			},
			getElevationPrice(id) {
				let price = []
				if (this.units[id] && this.units[id].packages) {
					this.units[id].packages.forEach(pack => {
						price.push(pack.price)
					})
				}
				let min = Math.min(...price)
				let max = Math.max(...price)
				if (min == 0 && max != 0) return `$${max}`
				if (min == max && min == 0) return 'N/A'
				if (min != max) return `$${min}-${max}`
				if (min == max) return `$${max}`
			},
            openFilter() {
                this.$store.commit('OPEN_FILTER')
            },
            getModelType(id) {
                let types = []
                if (this.storeLots[id].units.length) {
                    this.storeLots[id].units.forEach(unit => {
                        let id = ''
                        if (typeof unit == 'string') id = unit
                        else if (typeof unit == 'object') id = unit.id
                        if(this.units[id] && this.units[id].unitGroup && !types.includes(this.units[id].unitGroup.type)) {
                            types.push(this.units[id].unitGroup.type)
                        }
                    })
                    if (types.length > 1) {
                        types = 'Various'
                    } else if (types.length == 1) {
                        types = types[0]
                    }
                } else {
                    return types = 'N/A'
                }
                return types
            },
			getSqft(id){
				let sqft = []
				if (this.storeLots[id].units.length) {
                    this.storeLots[id].units.forEach(unit => {
                        let id = ''
                        if (typeof unit == 'string') id = unit
                        else if (typeof unit == 'object') id = unit.id
						if (this.units[id]) {
							if (this.units[id].packages && this.units[id].packages.length > 1) {
								let minSqft = Math.min(...this.units[id].packages.map(pack => pack.sqft))
								let maxSqft = Math.max(...this.units[id].packages.map(pack => pack.sqft))
								// if minSqft != maxSqft
								if (minSqft != maxSqft) {
									sqft.push(`${minSqft.toLocaleString('US')} - ${maxSqft.toLocaleString('US')}`)
								} else {
									sqft.push(`${minSqft.toLocaleString('US')}`)
								}
							} else {
								sqft.push(`${this.units[id].packages[0].sqft.toLocaleString('US')}`)
							}
						}
                    })
                } else {
                    return ['N/A']
                }
				console.log('SQFT', sqft)
				return sqft
			},
            getFrontage(id) {
                let frontage = []
                if (!this.storeLots[id].units.length) {
                    return 'N/A'
                } else {
                    this.storeLots[id].units.forEach(unit => {
                        let id = ''
                        if (typeof unit == 'string') id = unit
                        else if (typeof unit == 'object') id = unit.id
                        if(this.units[id] && this.units[id].unitGroup && !frontage.includes(this.units[id].unitGroup.size)) {
                            frontage.push(this.units[id].unitGroup.size)
                        }
                    })
                    if (frontage.length == 1) {
                        return frontage[0]
                    } else if (frontage.length > 1) {
                        let min = Math.min(...frontage)
                        let max = Math.max(...frontage)
                        return `${min}-${max}`
                    }
                }
            },
            getCondition(id) {
                let conditions = {
                    "none":"None",
                    "sideRearUpgrade":"Side and Rear Upgrade",
                    "wob":"Walk-out Basement",
                    "rearUpgrade":"Rear Upgrade",
                    "other":"Other",
                    "cornerUpgrade":"Corner Upgrade"
                }
                let conds = []
                if (typeof this.storeLots[id].condition == 'string') {
                    return [conditions[this.storeLots[id].condition]]
                } else {
                    this.storeLots[id].condition.forEach(x => {
                        if (x != 'none') {
                            conds.push(conditions[x])
                        }
                    })
                    return conds
                }
            },
            getLotPremiumPrice(id) {
                let price = 0

                let lot = this.$store.state.inventory.lots[id]
				if (lot.other && lot.other.premiums && Object.keys(lot.other.premiums).length) {
					Object.values(lot.other.premiums).forEach(premium => {
						if (premium.active) {
							price = price + premium.price
						}
					})
				}

                return price
            },
            getPremiums(id,withPrice) {
                let prems = []
                let lot = this.$store.state.inventory.lots[id]
                if (lot.other && lot.other.premiums && Object.keys(lot.other.premiums).length) {
                    Object.values(lot.other.premiums).forEach(prem => {
                        if (prem.active){
                            let label = prem.name
                            if (withPrice) {
                                label = `${label} $${prem.price.toLocaleString('US')}`
                            }
                            prems.push(label)
                        }
                    })
                }


                return prems
            },
            getGradings(id,withPrice) {
                let grads = []
                if (this.storeLots[id].other.gradings && Object.keys(this.storeLots[id].other.gradings).length) {
                    Object.values(this.storeLots[id].other.gradings).forEach(grad => {
                        if (grad.active){
                            let label = grad.name
                            if (withPrice && grad.price) {
                                label = `${label} $${grad.price.toLocaleString('US')}`
                            }
                            grads.push(label)
                        }
                    })
                }

                return grads
            },
            getPremium(id) {
                let price = 0
                if (this.storeLots[id].premiums.length == 0) return 0
                else {
                    this.storeLots[id].premiums.forEach(x => {
                        price = price + x.value
                    })
                }
            },
			updateScrollVisibility(){
				let scrollContent = document.querySelector('.price-div');

				if(scrollContent) {
					if(scrollContent.offsetWidth < scrollContent.scrollWidth){
						this.showScrollArrows = true;
					} else {
						this.showScrollArrows = false;
					}
				}
			},

			scrollLeft(id) {
				const scrollableArea = document.getElementById(id);
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: -scrollSize,
					behavior: "smooth",
				});
			},

			scrollRight(id) {
				const scrollableArea = document.getElementById(id);
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: scrollSize,
					behavior: "smooth",
				});
			},
        },
        created() {
			this.$store.commit('UPDATE_CRUMBS', 'priceManagement')
		},
		mounted() {
			let scrollContent = document.querySelector('.price-div');
			this.resizeObserver = new ResizeObserver(() => {
				window.requestAnimationFrame(() => {
					this.updateScrollVisibility()
				})
			});
			if(scrollContent){
				this.resizeObserver.observe(scrollContent);
			}

			// Remove the previous event listener, if any, to prevent multiple bindings
			window.removeEventListener('resize', this.updateScrollVisibility);

			// Attach the updated event listener
			window.addEventListener('resize', this.updateScrollVisibility)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.updateScrollVisibility)
			if (this.resizeObserver) {
				this.resizeObserver.disconnect();
			}
		},
    }
</script>

<style scoped>
.grid-display-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display:grid;
}
.gap-5{
    gap:1.25rem;
}
</style>
