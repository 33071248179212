<template>
    <a-modal
        :visible="visible"
        width="500px"
        :footer="null"
        @ok="onOk"
        @cancel="$store.commit('CLOSE_HIGHRISE_BUMP')"
        :centered="true"
    >
        <div class="mb-2">
            <template v-if="priceBump.ids.length > 1">
                <h5 class="mb-3" v-text="title" />
                <a-checkbox v-model="updateAll" class="mb-3"
                    >Update All</a-checkbox
                >
                <div
                    v-for="(id, index) in Object.keys(selectedTypeObject)"
                    :key="id"
                    :class="index != 0 ? 'mt-3' : ''"
                    class="dF aC"
                >
                    <div style="width: 200px">
                        {{ selectedTypeObject[id].label }}
                    </div>
                    <InputBox
                        v-if="updateAll"
                        style="flex: 1"
                        :percentage="percentage"
                        :dir="dir"
                        :amount="amount"
                        @changeValue="(e) => changeValue(e)"
                    />
                    <InputBox
                        v-else
                        style="flex: 1"
                        :percentage="selectedTypeObject[id].percentage"
                        :dir="selectedTypeObject[id].dir"
                        :amount="selectedTypeObject[id].amount"
                        @changeValue="(e) => changeValue(e, id, selectedType)"
                    />
                </div>
            </template>
            <template v-else>
                <h5 class="mb-3" v-text="title" />
                <div class="my-4">
                    <InputBox
                        style="flex: 1"
                        :percentage="percentage"
                        :dir="dir"
                        :amount="amount"
                        @changeValue="(e) => changeValue(e)"
                    />
                </div>
            </template>
        </div>
        <div class="dF jE mt-3">
            <a-button
                class="cancel-button"
                @click="$store.commit('CLOSE_HIGHRISE_BUMP')"
                >CANCEL</a-button
            >
            <a-button class="ml-3" type="primary" @click="onOk"
                >UPDATE</a-button
            >
        </div>
    </a-modal>
</template>

<script>
import InputBox from "@/components/inventory/InputBox";
export default {
    components: {
        InputBox,
    },
    data: () => ({
        percentage: false,
        dir: 1,
        amount: 1,
        ids: [],
        type: "",
        updateAll: false,
        unitsToAdjust: {},
        premiumsToAdjust: {},
        parkingToAdjust: {},
        lockersToAdjust: {},
        bikeRacksToAdjust: {},
    }),
    watch: {
        visible(val) {
            if (val) {
                this.percentage = false;
                this.dir = 1;
                this.amount = 1;

                if (
                    this.priceBump.type === "units" &&
                    this.selectedUnits.length > 1
                ) {
                    this.selectedUnits.map((u) => {
                        this.unitsToAdjust[u.id] = {
                            label: "Unit #" + u.unitNumber,
                            id: u.id,
                            dir: 1,
                            amount: 1,
                            percentage: false,
                        };
                    });
                } else if (
                    this.priceBump.type === "premium" &&
                    this.selectedPremiums.length > 1
                ) {
                    this.selectedPremiums.map((p) => {
                        this.premiumsToAdjust[p.id] = {
                            label: p.name,
                            id: p.id,
                            dir: 1,
                            amount: 1,
                            percentage: false,
                        };
                    });
                } else if (
                    this.priceBump.type === "parking" &&
                    this.selectedParking.length > 1
                ) {
                    this.selectedParking.map((p) => {
                        this.parkingToAdjust[p.id] = {
                            label: "Parking #" + p.name,
                            id: p.id,
                            dir: 1,
                            amount: 1,
                            percentage: false,
                        };
                    });
                } else if (
                    this.priceBump.type === "lockers" &&
                    this.selectedLockers.length > 1
                ) {
                    this.selectedLockers.map((l) => {
                        this.lockersToAdjust[l.id] = {
                            label: "Locker #" + l.name,
                            id: l.id,
                            dir: 1,
                            amount: 1,
                            percentage: false,
                        };
                    });
                } else if (
                    this.priceBump.type === "bikeracks" &&
                    this.selectedBikeRacks.length > 1
                ) {
                    this.selectedBikeRacks.map((b) => {
                        this.bikeRacksToAdjust[b.id] = {
                            label: "Bike Rack #" + b.name,
                            id: b.id,
                            dir: 1,
                            amount: 1,
                            percentage: false,
                        };
                    });
                }
            } else {
                this.unitsToAdjust = {};
                this.premiumsToAdjust = {};
                this.parkingToAdjust = {};
                this.lockersToAdjust = {};
                this.bikeRacksToAdjust = {};
                this.updateAll = false;
            }
        },
    },
    computed: {
        title() {
            return "Adjust Price";
        },
        priceBump() {
            return this.$store.state.inventory.highrisePriceBump;
        },
        visible: {
            get() {
                return this.priceBump.visible;
            },
            set(value) {
                if (!value) {
                    this.$store.commit("CLOSE_HIGHRISE_BUMP");
                }
            },
        },
        selectedTypeObject() {
            if (this.priceBump.type === "units") {
                return this.unitsToAdjust;
            } else if (this.priceBump.type === "premium") {
                return this.premiumsToAdjust;
            } else if (this.priceBump.type === "parking") {
                return this.parkingToAdjust;
            } else if (this.priceBump.type === "lockers") {
                return this.lockersToAdjust;
            } else if (this.priceBump.type === "bikeracks") {
                return this.bikeRacksToAdjust;
            }
            return {};
        },
        selectedType() {
            if (this.priceBump.type === "units") {
                return "unitsToAdjust";
            } else if (this.priceBump.type === "premium") {
                return "premiumsToAdjust";
            } else if (this.priceBump.type === "parking") {
                return "parkingToAdjust";
            } else if (this.priceBump.type === "lockers") {
                return "lockersToAdjust";
            } else if (this.priceBump.type === "bikeracks") {
                return "bikeRacksToAdjust";
            }
            return "";
        },
        units() {
            return this.$store.state.inventory.units;
        },
        selectedUnits() {
            let units = [];
            this.priceBump.ids.forEach((id) => {
                if (this.units[id]) {
                    units.push(this.units[id]);
                }
            });
            return units;
        },
        storedUnits() {
            let units = {};
            const orderAlpha = (array) => {
                array = array.sort((a, b) => {
                    if (parseInt(a.unitNumber) < parseInt(b.unitNumber))
                        return -1;
                    if (parseInt(a.unitNumber) > parseInt(b.unitNumber))
                        return 1;
                    else return 0;
                });
                return array;
            };
            if (this.$store.state.instance.productType === "highrise") {
                orderAlpha(
                    Object.values(this.$store.state.inventory.units)
                ).forEach((unit) => {
                    if (unit.buildingType === "condounits") {
                        units[unit.id] = unit;
                    }
                });
            }
            return units;
        },
        condoPremiums() {
            return this.$store.state.inventory.condoPremiums;
        },
        selectedPremiums() {
            let units = Object.values(this.storedUnits);
            let cPremiums = JSON.parse(JSON.stringify(this.condoPremiums));

            units.forEach((x) => {
                for (var y in x.packages[0].other.premiums) {
                    let y2 = x.packages[0].other.premiums[y];
                    if (cPremiums.hasOwnProperty(y)) {
                        if (y2.active) {
                            cPremiums[y].units.push(x.id);
                        }
                    }
                }
            });

            let premiums = [];
            this.priceBump.ids.forEach((id) => {
                if (cPremiums[id]) {
                    premiums.push(cPremiums[id]);
                }
            });
            return premiums;
        },
        condoAddons() {
            return this.$store.state.inventory.condoAddons;
        },
        selectedParking() {
            let parking = [];
            this.priceBump.ids.forEach((id) => {
                const find = this.condoAddons.parking.find((p) => p.id === id);
                if (find) {
                    parking.push(find);
                }
            });
            return parking;
        },
        selectedLockers() {
            let lockers = [];
            this.priceBump.ids.forEach((id) => {
                const find = this.condoAddons.lockers.find((l) => l.id === id);
                if (find) {
                    lockers.push(find);
                }
            });
            return lockers;
        },
        selectedBikeRacks() {
            let bikeRacks = [];
            this.priceBump.ids.forEach((id) => {
                const find = this.condoAddons.bikeRacks.find(
                    (b) => b.id === id
                );
                if (find) {
                    bikeRacks.push(find);
                }
            });
            return bikeRacks;
        },
    },
    methods: {
        changeValue(value, id, type = "unitsToAdjust") {
            if (!id) {
                this.amount = value.amount;
                this.percentage = value.percentage;
                this.dir = value.dir;
            } else if (this[type] && this[type][id]) {
                let obj = JSON.parse(JSON.stringify(this[type]));

                obj[id].amount = value.amount;
                obj[id].percentage = value.percentage;
                obj[id].dir = value.dir;

                this[type] = obj;
            }
        },
        onOk() {
            if (this.amount === 0) {
                return this.$message.warning("Amount cannot be zero");
            }

            const sendObj = {
                type: this.priceBump.type,
                percentage: this.percentage,
                amount: this.dir > 0 ? this.amount : -1 * this.amount,
                ids: this.priceBump.ids,
            };

            const type = this[this.selectedType];
            if (type && Object.keys(type).length > 0) {
                sendObj.priceBump = Object.values(type).map((t) => {
                    return {
                        id: t.id,
                        amount: t.dir > 0 ? t.amount : -1 * t.amount,
                        percentage: t.percentage,
                    };
                });
            }
            if (this.updateAll && type && Object.keys(type).length > 0) {
                sendObj.priceBump = Object.values(type).map((t) => {
                    return {
                        id: t.id,
                        amount: this.dir > 0 ? this.amount : -1 * this.amount,
                        percentage: this.percentage,
                    };
                });
            }

            this.$store.commit("LOAD", true);
            this.$api
                .put(`/sales/:instance/priceBump/hr`, sendObj)
                .then(() => {
                    this.$store.commit("DO_BUMP", {
                        ...sendObj,
                        instance: this.$store.state.instance,
                    });
                    this.$store.commit("CLOSE_HIGHRISE_BUMP");
                })
                .catch((err) => {
                    this.$message.error("An error occurred. Please try again");
                    console.error(err);
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        },
    },
};
</script>

<style>
.cancel-button.ant-btn {
    border-color: #ece9f1 !important;
    background-color: #ece9f1;
    color: #3f3356;
}
.adjust-price-box {
    display: flex;
    align-items: center;
}
.adjust-amount {
    width: 100%;
    grid-column: 2/3;
    grid-row: 1/3;
    height: 100%;
}
.adjust-amount .ant-input-number,
.adjust-amount .ant-input-number-input,
.adjust-amount .ant-input-number-input-wrap {
    width: 100%;
    text-align: center;
    height: 100%;
    border-radius: 0;
    border: 0;
}
.adjust-amount .ant-input-number-input {
    font-size: 20px;
}
.adjust-amount.increase .ant-input-number-input {
    color: var(--green);
}
.adjust-amount.decrease .ant-input-number-input {
    color: var(--red);
}
.adjust-price-box {
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #f1f1f1;
}
.adjust-price-box svg {
    width: 12px;
    height: 12px;
    color: #9ea0a5;
}

.adjust-price-box i {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.adjust-price-box div.active {
    background: white;
}
.adjust-price-box i.active {
    background: white;
}
.adjust-price-box i.active svg {
    color: black;
}
.ad-topleft {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    justify-self: center;
    align-self: center;
    border-top-left-radius: 4px;
}
.ad-topright {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
    justify-self: center;
    align-self: center;
    border-top-left-radius: 4px;
}
.ad-botleft {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    justify-self: center;
    align-self: center;
    border-bottom-left-radius: 4px;
}
.ad-botright {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
    justify-self: center;
    align-self: center;
    border-bottom-right-radius: 4px;
}
</style>
