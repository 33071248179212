<template>
    <div class="adjust-price-box" style="flex:1">
        <div class="dF aC" style="width: 57px;">
            <div class="f1" :class="dir === -1 ? 'active' : ''" style="height: 27px;">
                <i @click="$emit('changeValue', {amount:amount, percentage:percentage, dir:-1})" :class="dir === -1 ? 'active' : ''" class="ad-topleft" tabindex="0" alt="minus">
                    <svg :class="dir < 0 ? 'text-danger' : ''" style="stroke:currentColor" viewBox="0 0 11.367 2">
                        <path d="M7.5,18h9.367" transform="translate(-6.5 -17)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </svg>
                </i>
            </div>
            <div class="f1" :class="dir === 1 ? 'active' : ''" style="height: 27px;">
                <i @click="$emit('changeValue', {amount:amount, percentage:percentage, dir:1})" :class="dir === 1 ? 'active' : ''" class="ad-botleft" tabindex="4" alt="plus">
                    <svg :class="dir > 0 ? 'text-success' : ''" style="stroke:currentColor" viewBox="0 0 11.2 11.2">
                        <g  transform="translate(1 1)">
                            <path d="M18,7.5v9.2" transform="translate(-13.4 -7.5)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            <path d="M7.5,18h9.2" transform="translate(-7.5 -13.4)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                    </svg>

                </i>
            </div>
        </div>
        
        <div class="adjust-amount f1" :class="dir > 0 ? 'increase' : 'decrease'">
            <a-input-number 
                :min="1"
                :max="10000000"
                :value="amount"
                @change="(e) => $emit('changeValue', {amount:e, percentage:percentage, dir:dir})"
                :formatter="formattedPrice"
                :parser="realAmount"
                class="w-full"
            />
        </div>

        <div class="dF aC" style="width: 57px;">
            <div class="f1" :class="!percentage? 'active' : ''" style="height: 27px;">
                <i  @click="$emit('changeValue', {amount:amount, percentage:false, dir:dir})" :class="!percentage? 'active' : ''" tabindex="1" alt="dollar sign">
                    <svg style="stroke:currentColor" viewBox="0 0 9.436 15.632">
                        <g transform="translate(1 1)">
                            <path d="M18,1.5V15.132" transform="translate(-14.282 -1.5)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            <path d="M15.816,7.5H11.169a2.169,2.169,0,1,0,0,4.338h3.1a2.169,2.169,0,0,1,0,4.338H9" transform="translate(-9 -5.021)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                    </svg>

                </i>
            </div>
            <div class="f1" :class="percentage? 'active' : ''" style="height: 27px;">
                <i @click="$emit('changeValue', {amount:amount, percentage:true, dir:dir})" :class="percentage? 'active' : ''" class="ad-botright" tabindex="5" alt="percentage">
                    <svg viewBox="0 0 12.866 12.866" style="stroke:currentColor">
                        <g  transform="translate(1 1)">
                            <path  d="M17.008,7.5,7.5,17.008" transform="translate(-6.821 -6.821)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            <path  d="M9.4,7.7A1.7,1.7,0,1,1,7.7,6,1.7,1.7,0,0,1,9.4,7.7Z" transform="translate(-6 -6)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            <path  d="M25.9,24.2a1.7,1.7,0,1,1-1.7-1.7A1.7,1.7,0,0,1,25.9,24.2Z" transform="translate(-15.029 -15.029)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                    </svg>

                </i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['percentage', 'dir', 'amount'],
    computed:{
    },
    data() {
        return{
        }
    },
    methods:{
        realAmount(value){
            if (this.percentage) {
                return value.replace('%','')
            }
            return value.replace(/\$\s?|(,*)/g, '')
        },
        formattedPrice(value){
            if (this.percentage) {
                return value + '%'
            }
            return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
    },
}
</script>

<style>
    .cancel-button.ant-btn {
        border-color:#ECE9F1 !important;
        background-color:#ECE9F1; 
        color:#3F3356
    }
    .adjust-price-box{
        display: flex;
        align-items: center;
    }
    .adjust-amount{
        width:100%;
        grid-column:2/3;
        grid-row:1/3;
        height:100%;
    }
    .adjust-amount .ant-input-number, .adjust-amount .ant-input-number-input, .adjust-amount .ant-input-number-input-wrap{
        width:100%;
        text-align:center;
        height:100%;
        border-radius:0;
        border:0;
    }
    .adjust-amount .ant-input-number-input{
        /* font-size:max(20px,1.5vw); */
        font-size:20px;
    }
    .adjust-amount.increase .ant-input-number-input{
        color:var(--green);
    }
    .adjust-amount.decrease .ant-input-number-input{
        color:var(--red);
    }
    .adjust-price-box{
        border:1px solid #ccc;
        border-radius:4px;
        background:#f1f1f1;
    }
    .adjust-price-box svg{
        width:12px;
        height:12px;
        color:#9ea0a5;
    }
    
    .adjust-price-box i{
        cursor:pointer;
        display:flex;
        justify-content:center;
        align-items:center;
        width:100%;
        height:100%;
    }
    .adjust-price-box div.active{
        background:white;
    }
    .adjust-price-box i.active{
        background:white;
    }
    .adjust-price-box i.active svg{
        color:black;
    }
    .ad-topleft{
        grid-column:1/span 1;
        grid-row: 1/span 1;
        justify-self:center;
        align-self:center;
        border-top-left-radius:4px;
    }
    .ad-topright{
        grid-column:3/span 1;
        grid-row: 1/span 1;
        justify-self:center;
        align-self:center;
        border-top-left-radius:4px;
    }
    .ad-botleft{
        grid-column:1/span 1;
        grid-row: 2/span 1;
        justify-self:center;
        align-self:center;
        border-bottom-left-radius:4px;
    }
    .ad-botright{
        grid-column:3/span 1;
        grid-row: 2/span 1;
        justify-self:center;
        align-self:center;
        border-bottom-right-radius:4px;
    }
</style>

