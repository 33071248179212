<template>
    <a-modal
        :visible="visible"
        width="500px"
        :footer="null"
        @ok="onOk"
        @cancel="$store.commit('CLOSE_BUMP')"
        :centered="true"
    >
        <div class="mb-2">
            <h5 class="mb-3" v-text="title" />
            <template v-if="priceBump.type == 'lots'">
                <div
                    v-if="premiumOptions.premiums.length"
                    :class="premiumOptions.premiums.length ? 'mb-2' : ''"
                >
                    <p class="mb-1">
                        <strong>Lot Conditions / Premiums:</strong>
                    </p>
                    <a-checkbox
                        v-if="Object.keys(premiumObjects).length > 1"
                        :checked="premiums.includes('all')"
                        @change="toggleSelect('all')"
                        class="mb-3"
                        >Select All</a-checkbox
                    >
                    <template v-if="Object.keys(premiumObjects).length">
                        <div
                            :class="premiumI != 0 ? 'mt-3' : ''"
                            class="dF aC"
                            v-for="(premium, premiumI) in Object.keys(
                                premiumObjects
                            )"
                            :key="premium"
                        >
                            <a-checkbox
                                :checked="premiums.includes(premium)"
                                @change="toggleSelect(premium)"
                                style="width: 200px"
                                >{{ premiumObjects[premium].label }}</a-checkbox
                            >
                            <InputBox
                                v-if="premiums.includes('all')"
                                style="flex: 1"
                                :percentage="percentage"
                                :dir="dir"
                                :amount="amount"
                                @changeValue="(e) => changeValue('all', e)"
                            />
                            <InputBox
                                v-else
                                style="flex: 1"
                                :percentage="premiumObjects[premium].percentage"
                                :dir="premiumObjects[premium].dir"
                                :amount="premiumObjects[premium].amount"
                                @changeValue="(e) => changeValue(premium, e)"
                            />
                        </div>
                    </template>
                </div>
                <div v-else style="color: var(--danger)">
                    This lot does not have any premiums
                </div>
                <div v-if="premiumOptions.gradings.length && false">
                    <p class="mb-1"><strong>Gradings:</strong></p>
                    <a-checkbox-group
                        v-model="gradings"
                        :options="premiumOptions.gradings"
                    >
                        <span
                            slot="label"
                            slot-scope="{ value }"
                            style="color: red"
                            >{{ value }}</span
                        >
                    </a-checkbox-group>
                </div>
            </template>
            <template v-if="priceBump.type == 'units'">
                <div v-if="Object.keys(commonPackages).length">
                    <p class="mb-1"><strong>Floorplan Packages:</strong></p>
                    <a-checkbox
                        :checked="packages.includes('all')"
                        @change="toggleSelect('all')"
                        class="mb-3"
                        >Select All</a-checkbox
                    >
                    <template v-if="Object.keys(packageObjects).length">
                        <div
                            :class="packI != 0 ? 'mt-3' : ''"
                            class="dF aC"
                            v-for="(pack, packI) in Object.keys(commonPackages)"
                            :key="pack + packI"
                        >
                            <a-checkbox
                                :checked="packages.includes(pack)"
                                @change="toggleSelect(pack)"
                                style="width: 200px"
                                >{{ pack }}</a-checkbox
                            >
                            <InputBox
                                v-if="packages.includes('all')"
                                style="flex: 1"
                                :percentage="percentage"
                                :dir="dir"
                                :amount="amount"
                                @changeValue="(e) => changeValue('all', e)"
                            />
                            <InputBox
                                v-else
                                style="flex: 1"
                                :percentage="packageObjects[pack].percentage"
                                :dir="packageObjects[pack].dir"
                                :amount="packageObjects[pack].amount"
                                @changeValue="(e) => changeValue(pack, e)"
                            />
                        </div>
                    </template>
                </div>
                <div v-else style="color: var(--danger)">
                    This elevation does not have any packages
                </div>
            </template>
        </div>
        <p
            v-if="
                priceBump.type === 'lots' &&
                !premiums.length &&
                !gradings.length &&
                premiumOptions.premiums.length
            "
            class="text-danger mb-0 mt-2"
        >
            Must select at least one Condition / Premium
        </p>
        <p
            v-if="
                priceBump.type === 'units' &&
                !packages.length &&
                Object.keys(commonPackages).length
            "
            class="text-danger mb-0 mt-2"
        >
            Must select at least one Package
        </p>
        <div class="dF jE mt-3">
            <a-button class="cancel-button" @click="$store.commit('CLOSE_BUMP')"
                >CANCEL</a-button
            >
            <a-button class="ml-3" type="primary" @click="onOk"
                >UPDATE</a-button
            >
        </div>
    </a-modal>
</template>

<script>
import InputBox from "@/components/inventory/InputBox";
export default {
    components: {
        InputBox,
    },
    data: () => ({
        premiums: [],
        packages: [],
        gradings: [],
        percentage: false,
        dir: 1,
        amount: 1,
        premiumObjects: {},
        packageObjects: {},
    }),
    watch: {
        visible(val) {
            if (val) {
                this.percentage = false;
                this.dir = 1;
                this.amount = 1;
                this.premiums = [];
                this.gradings = [];
                this.packages = [];

                if (
                    this.premiumOptions.premiums &&
                    this.premiumOptions.premiums.length
                ) {
                    this.premiumOptions.premiums.forEach((prem) => {
                        this.premiumObjects[prem.value] = {
                            label: prem.label,
                            value: prem.value,
                            dir: 1,
                            amount: 1,
                            percentage: false,
                        };
                    });
                }
                if (Object.keys(this.commonPackages).length) {
                    Object.keys(this.commonPackages).forEach((key) => {
                        this.packageObjects[key] = {
                            value: key,
                            label: key,
                            dir: 1,
                            amount: 1,
                            percentage: false,
                        };
                    });
                }
            } else {
                this.premiumObjects = {};
                this.packageObjects = {};
            }
        },
    },
    computed: {
        title() {
            return this.priceBump.type === "lots" &&
                this.priceBump.ids.length == 1
                ? `Adjust lot ${this.lots[this.priceBump.ids[0]].name} price`
                : this.priceBump.type == "lots" && this.priceBump.ids.length > 1
                ? `Bulk adjust ${this.priceBump.ids.length} lots`
                : this.priceBump.type === "units" &&
                  this.priceBump.ids.length == 1
                ? `Adjust ${this.units[this.priceBump.ids[0]].unitGroup.name} ${
                      this.units[this.priceBump.ids[0]].name
                  } elevation`
                : this.priceBump.type === "units" &&
                  this.priceBump.ids.length > 1
                ? `Bulk adjust ${this.priceBump.ids.length} elevations`
                : this.priceBump.type === "condounits"
                ? `Adjust Condo Units Prices`
                : ``;
        },
        premiumOptions() {
            let premiums = Object.values(this.commonPremiums.premiums);
            let gradings = Object.values(this.commonPremiums.gradings);
            let prems = premiums.map((premium) => {
                return {
                    label: premium.name,
                    value: premium.id,
                };
            });
            let grads = gradings.map((grading) => {
                return {
                    label: grading.name,
                    value: grading.id,
                };
            });
            return { premiums: prems, gradings: grads };
        },
        storePremiums() {
            return this.$store.state.inventory.premiums;
        },
        storeGradings() {
            return this.$store.state.inventory.gradings;
        },
        lots() {
            return this.$store.state.inventory.lots;
        },
        units() {
            return this.$store.state.inventory.units;
        },
        commonPackages() {
            let packs = {};
            if (this.priceBump.ids.length && this.priceBump.type == "units") {
                this.priceBump.ids.forEach((id) => {
					this.units[id].packages.forEach((pack) => {
						if (pack && pack.name) {
							if (!packs[pack.name.trim()]) {
								packs[pack.name.trim()] = [pack.id];
							} else {
								packs[pack.name.trim()].push(pack.id);
							}
						}
					});
                });
            }

            return packs;
        },
        commonPremiums() {
            let foundPremiums = {};
            let foundGradings = {};
            this.selectedLots.forEach((lot) => {
                if (
                    lot.other &&
                    lot.other.premiums &&
                    Object.values(lot.other.premiums).length
                ) {
                    let premiums = lot.other.premiums;
                    Object.keys(premiums).forEach((premiumId) => {
                        if (
                            premiums[premiumId].active &&
                            this.storePremiums[premiumId]
                        ) {
                            foundPremiums[premiumId] =
                                this.storePremiums[premiumId];
                        }
                    });
                }
                if (
                    lot.other &&
                    lot.other.gradings &&
                    Object.values(lot.other.gradings).length
                ) {
                    let gradings = lot.other.gradings;
                    Object.keys(gradings).forEach((gradingId) => {
                        if (
                            gradings[gradingId].active &&
                            this.storeGradings[gradingId]
                        ) {
                            foundGradings[gradingId] =
                                this.storeGradings[gradingId];
                        }
                    });
                }
            });

            return { premiums: foundPremiums, gradings: foundGradings };
        },
        selectedLots() {
            let lots = [];
            this.priceBump.ids.forEach((id) => {
                if (this.lots[id]) {
                    lots.push(this.lots[id]);
                }
            });
            return lots;
        },
        amountToString() {
            let amount = this.amount;
            let dir = this.dir > 0 ? "+" : "-";
            if (this.percentage) amount = dir + amount + "%";
            else amount = "$" + amount.toLocaleString("US");
            return amount;
        },
        actionSummary() {
            let lookup = {
                lots: "lot premiums/gradings",
                units: "elevations",
                condounits: "Condo units",
            };
            let amount = this.amount;
            let dir = this.dir > 0 ? "+" : "-";
            if (this.percentage) amount = dir + amount + "%";
            else amount = dir + "$" + amount.toLocaleString("US");
            return `You are about to ${
                this.dir > 0 ? "increase" : "decrease"
            } the price of the selected ${lookup[this.priceBump.type]} by `;
        },
        priceBump() {
            return this.$store.state.inventory.priceBump;
        },
        visible: {
            get() {
                return this.priceBump.visible;
            },
            set(value) {
                if (!value) {
                    this.$store.commit("CLOSE_BUMP");
                }
            },
        },
    },
    methods: {
        changeValue(type, value) {
            if (type == "all") {
                this.amount = value.amount;
                this.percentage = value.percentage;
                this.dir = value.dir;
            } else {
                if (this.priceBump.type == "lots") {
                    let obj = JSON.parse(JSON.stringify(this.premiumObjects));
                    obj[type].amount = value.amount;
                    obj[type].percentage = value.percentage;
                    obj[type].dir = value.dir;
                    this.premiumObjects = obj;
                }
                if (this.priceBump.type == "units") {
                    let obj = JSON.parse(JSON.stringify(this.packageObjects));
                    obj[type].amount = value.amount;
                    obj[type].percentage = value.percentage;
                    obj[type].dir = value.dir;
                    this.packageObjects = obj;
                }
            }
        },
        toggleSelect(value) {
            if (this.priceBump.type == "lots") {
                if (value == "all") this.premiums = ["all"];
                else {
                    if (this.premiums.includes("all")) {
                        let index = this.premiums.findIndex((x) => x == "all");
                        this.premiums.splice(index, 1);
                    }
                    if (this.premiums.includes(value)) {
                        let index = this.premiums.findIndex((x) => x == value);
                        this.premiums.splice(index, 1);
                    } else {
                        this.premiums.push(value);
                    }
                }
            }
            if (this.priceBump.type == "units") {
                if (value == "all") this.packages = ["all"];
                else {
                    if (this.packages.includes("all")) {
                        let index = this.packages.findIndex((x) => x == "all");
                        this.packages.splice(index, 1);
                    }
                    if (this.packages.includes(value)) {
                        let index = this.packages.findIndex((x) => x == value);
                        this.packages.splice(index, 1);
                    } else {
                        this.packages.push(value);
                    }
                }
            }
        },
        realAmount(value) {
            if (this.percentage) {
                return value.replace("%", "");
            }
            return value.replace(/\$\s?|(,*)/g, "");
        },
        formattedPrice(value) {
            if (this.percentage) {
                return value + "%";
            }
            return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        onOk() {
            if (
                this.priceBump.type == "lots" &&
                this.premiumOptions.premiums.length == 0
            )
                return this.$store.commit("CLOSE_BUMP");
            if (
                this.priceBump.type === "lots" &&
                !this.premiums.length &&
                !this.gradings.length
            ) {
                return this.$message.warning(
                    "Must select at least one Condition / Premium"
                );
            }
            if (this.priceBump.type == "units" && !this.packages.length)
                return this.$message.warning(
                    "Must select at least one Package"
                );
            if (this.amount === 0) {
                return this.$message.warning("Amount cannot be zero");
            }
            let sendObj = {
                type: this.priceBump.type,
                ids: this.priceBump.ids,
                premiums: [],
                gradings: this.gradings.map((x) => this.storeGradings[x]),
                packages: [],
            };
            if (this.priceBump.type == "lots") {
                if (!this.premiums.includes("all")) {
                    sendObj.premiums = this.premiums.map((x) => {
                        let obj = {};
                        obj = this.storePremiums[x];
                        obj.amount =
                            this.premiumObjects[x].dir > 0
                                ? this.premiumObjects[x].amount
                                : -1 * this.premiumObjects[x].amount;
                        obj.percentage = this.premiumObjects[x].percentage;

                        return obj;
                    });
                } else {
                    sendObj.premiums = Object.keys(this.premiumObjects).map(
                        (x) => {
                            let obj = {};
                            obj = this.storePremiums[x];
                            obj.amount =
                                this.dir > 0 ? this.amount : -1 * this.amount;
                            obj.percentage = this.percentage;

                            return obj;
                        }
                    );
                }
            }
            if (this.priceBump.type == "units") {
                let packs = [];
                if (!this.packages.includes("all")) {
                    this.packages.forEach((key) => {
                        this.commonPackages[key].forEach((id) => {
                            let obj = {
                                id: id,
                                amount:
                                    this.packageObjects[key].dir > 0
                                        ? this.packageObjects[key].amount
                                        : -1 * this.packageObjects[key].amount,
                                percentage: this.packageObjects[key].percentage,
                            };
                            packs.push(obj);
                        });
                    });
                } else {
                    Object.keys(this.commonPackages).forEach((key) => {
                        this.commonPackages[key].forEach((id) => {
                            let obj = {
                                id: id,
                                amount:
                                    this.dir > 0
                                        ? this.amount
                                        : -1 * this.amount,
                                percentage: this.percentage,
                            };
                            packs.push(obj);
                        });
                    });
                }
                sendObj.packages = packs;
            }
            this.$store.commit("LOAD", true);
            this.$api
                .put(`/sales/:instance/priceBump/lr`, sendObj)
                .then(({ data }) => {
                    this.$store.commit("DO_BUMP", sendObj);
                    this.priceBump.cb();
                    this.$store.commit("CLOSE_BUMP");
                    location.reload();
                })
                .catch((err) => {
                    this.$message.error("An error occurred. Please try again");
                    console.error(err);
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        },
    },
};
</script>

<style>
.cancel-button.ant-btn {
    border-color: #ece9f1 !important;
    background-color: #ece9f1;
    color: #3f3356;
}
.adjust-price-box {
    display: flex;
    align-items: center;
}
.adjust-amount {
    width: 100%;
    grid-column: 2/3;
    grid-row: 1/3;
    height: 100%;
}
.adjust-amount .ant-input-number,
.adjust-amount .ant-input-number-input,
.adjust-amount .ant-input-number-input-wrap {
    width: 100%;
    text-align: center;
    height: 100%;
    border-radius: 0;
    border: 0;
}
.adjust-amount .ant-input-number-input {
    font-size: 20px;
}
.adjust-amount.increase .ant-input-number-input {
    color: var(--green);
}
.adjust-amount.decrease .ant-input-number-input {
    color: var(--red);
}
.adjust-price-box {
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #f1f1f1;
}
.adjust-price-box svg {
    width: 12px;
    height: 12px;
    color: #9ea0a5;
}

.adjust-price-box i {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.adjust-price-box div.active {
    background: white;
}
.adjust-price-box i.active {
    background: white;
}
.adjust-price-box i.active svg {
    color: black;
}
.ad-topleft {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    justify-self: center;
    align-self: center;
    border-top-left-radius: 4px;
}
.ad-topright {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
    justify-self: center;
    align-self: center;
    border-top-left-radius: 4px;
}
.ad-botleft {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    justify-self: center;
    align-self: center;
    border-bottom-left-radius: 4px;
}
.ad-botright {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
    justify-self: center;
    align-self: center;
    border-bottom-right-radius: 4px;
}
</style>
