<template>
	<div style="background-color: white;" :data-elevation-id="id">
		<div class="display-card py-3 px-3">
			<div class="dF aC" style="justify-content:space-between;">
				<div id="left" v-if="showScrollArrows" @click="scrollLeft(id)"><a-icon type="left" /></div>
				<div :id="id" class="price-content price-div">
					<div class="dF">
						<a-checkbox @change="$emit('select')" class="pr-3 pt-2" :checked="selected" />
						<div class="pr-3" style="min-width:100px; width:130px">
							<div style="color:#9EA0A5">Elevation</div>
							<div>{{ displayName }}</div>
						</div>
					</div>
					<div class="pr-3" style="min-width:100px; width:130px">
						<div style="color:#9EA0A5">Model Type</div>
						<div>{{ unit.unitGroup.type }}</div>
					</div>
					<div class="pr-3" style="min-width:100px; width:130px">
						<div style="color:#9EA0A5">Frontage</div>
						<div>{{ unit.unitGroup.size }}</div>
					</div>
					<div class="pr-3" style="min-width:100px; width:130px">
						<div style="color:#9EA0A5">Bedrooms</div>
						<div v-for="pack in unit.packages" :key="pack.id">{{ pack.beds }}</div>
					</div>
					<div class="pr-3" style="min-width:100px; width:130px">
						<div style="color:#9EA0A5">Bathrooms</div>
						<div v-for="pack in unit.packages" :key="pack.id">{{ pack.baths }}</div>
					</div>
					<div class="pr-3" style="min-width:100px; width:130px">
						<div style="color:#9EA0A5">Garages</div>
						<div v-for="pack in unit.packages" :key="pack.id">{{ pack.garages }}</div>
					</div>
					<div class="pr-3" style="min-width:100px; width:130px">
						<div style="color:#9EA0A5">Area</div>
						<div v-for="pack in unit.packages" :key="pack.id">{{ `${pack.sqft} Sq. Ft.` }}</div>
					</div>
					<div class="pr-3" style="min-width:120px; width:180px">
						<div style="color:#9EA0A5">Floorplan Packages</div>
						<div v-for="pack in unit.packages" :key="pack.id">{{ pack.name }}</div>
					</div>
					<div class="pr-3" style="min-width:100px; width:130px">
						<div style="color:#9EA0A5">Price</div>
						<div v-for="pack in unit.packages" :key="pack.id" class="hover-dotted">{{ `$${pack.price &&
							pack.price.toLocaleString()}` }}</div>
					</div>
				</div>
				<div id="right" v-if="showScrollArrows" @click="scrollRight(id)"><a-icon type="right" /></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['id', 'selected'],
	computed: {
		displayName() {
			return `${this.unit.unitGroup.name} ${this.unit.name}`
		},
		unit() {
			return this.$store.state.inventory.units[this.id]
		}
	},
	data() {
		return {
			showScrollArrows: false,
			resizeObserver: null
		}
	},
	methods: {
		updateScrollVisibility() {
			let scrollContent = document.querySelector('.price-div');

			if (scrollContent) {
				if (scrollContent.offsetWidth < scrollContent.scrollWidth) {
					this.showScrollArrows = true;
				} else {
					this.showScrollArrows = false;
				}
			}
		},

		scrollLeft(id) {
			const scrollableArea = document.getElementById(id);
			const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
			scrollableArea.scrollBy({
				left: -scrollSize,
				behavior: "smooth",
			});
		},

		scrollRight(id) {
			const scrollableArea = document.getElementById(id);
			const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
			scrollableArea.scrollBy({
				left: scrollSize,
				behavior: "smooth",
			});
		},
	},

	mounted() {
		let scrollContent = document.querySelector('.price-div');
		this.resizeObserver = new ResizeObserver(() => {
			window.requestAnimationFrame(() => {
				this.updateScrollVisibility()
			})
		});
		if(scrollContent){
			this.resizeObserver.observe(scrollContent);
		}

		// Remove the previous event listener, if any, to prevent multiple bindings
		window.removeEventListener('resize', this.updateScrollVisibility);

		// Attach the updated event listener
		window.addEventListener('resize', this.updateScrollVisibility)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateScrollVisibility)
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	},
}
</script>

<style>

</style>
