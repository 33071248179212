<template>
    <div
        class="w-full dF fC f1 px-4 pb-4 hide-scrollbar"
        style="overflow-y: scroll"
    >
        <FilterDrawer />
        <PriceBump />
        <HighrisePriceBump />
        <LowrisePrice v-if="instance.productType == 'lowrise' && Object.values(lots).length != 0"  />
        <HighrisePrice v-else-if="instance.productType == 'highrise' && Object.values(units).length != 0" />
    </div>
</template>

<script>
import LowrisePrice from "@/components/inventory/LowrisePrice";
import HighrisePrice from "@/components/inventory/HighrisePrice";
import FilterDrawer from "@/components/inventory/FilterDrawer";
import PriceBump from "@/components/inventory/PriceBump";
import HighrisePriceBump from "@/components/inventory/HighrisePriceBump";
export default {
    components: {
        LowrisePrice,
        FilterDrawer,
        PriceBump,
        HighrisePrice,
        HighrisePriceBump,
    },
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        lots() {
            return this.$store.state.inventory.lots;
        },
        units() {
            return this.$store.state.inventory.units;
        },
        tags() {
            return this.$store.state.inventory.tags;
        },
    },
    async created() {
		this.$store.commit("UPDATE_CRUMBS", "priceManagement");
		if (
			Object.values(this.tags).length &&
			Object.values(this.tags).filter((x) => x && x.name).length !=
			Object.values(this.tags).length
		) {
			this.$store.commit("LOAD", true);
			try {
				let lotTags = await this.$api.get("/tags/:instance?type=lots");
				let unitTags = await this.$api.get("/tags/:instance?type=models");

				let tags = lotTags.data.concat(unitTags.data);
				this.$store.commit("DO_TAGS", tags);
				this.$store.commit("LOAD", false);
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
		}
	},
};
</script>

<style>
</style>
